import moment from "moment";

type DateFormatOptions = "MM/DD/YYYY" | "DD/MM/YYYY" | "YYYY-MM-DD";
type TimeFormatOptions = "HH:mm:ss" | "h:mm:ss A" | "HH:mm:ss.SSS";

interface GetDateFormatOptions {
  isMoment?: boolean;
  includeTime?: boolean;
  includeFractionalSecs?: boolean;
}

// union type for monolith-ui date format
export type DateInputFormat =
  | DateFormatOptions
  | `${DateFormatOptions} ${TimeFormatOptions}`;

const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";

// Utility function to allow users to set their own date format
export function getDateFormat({
  isMoment = true,
  includeTime = true,
  includeFractionalSecs = false,
}: GetDateFormatOptions = {}): string {
  // Adding allowing string type for now in case current customers have an invalid date type in local storage
  const dateFormat: DateFormatOptions | string =
    localStorage.getItem("dateFormat.date") || DEFAULT_DATE_FORMAT;
  const timeFormat: TimeFormatOptions | string =
    localStorage.getItem("dateFormat.time") || DEFAULT_TIME_FORMAT;

  // Return format compatible with moment.js
  if (isMoment)
    return (
      dateFormat +
      (includeTime ? ` ${timeFormat}` : "") +
      (includeFractionalSecs ? ".SSS" : "")
    );

  // Return format compatible with JS Date
  const jsDateFormat =
    {
      "YYYY-MM-DD": "yyyy-MM-dd",
      "DD/MM/YYYY": "dd/MM/yyyy",
      "MM/DD/YYYY": "MM/dd/yyyy",
    }[dateFormat] || "yyyy-MM-dd";

  if (includeTime) {
    return `${jsDateFormat} ${timeFormat.replace("A", "a")}`;
  }
  return jsDateFormat;
}

interface MonolithMomentOptions {
  timestamp?: string | number | Date;
  includeTime?: boolean;
  includeZone?: boolean;
  format?: string;
}

//convert from UTC to local time - for displaying times in Monolith
export function monolithMoment(options: MonolithMomentOptions = {}): string {
  if (!options.timestamp) return "";

  const dateFormat =
    localStorage.getItem("dateFormat.date") || DEFAULT_DATE_FORMAT;
  const timeFormat = localStorage.getItem("dateFormat.time") || "HH:mm";

  const format = [
    dateFormat,
    options.includeTime ? timeFormat : "",
    options.includeZone ? "ZZ" : "",
  ]
    .filter(Boolean)
    .join(" ")
    .trim();

  const finalFormat = options.format || format;

  return !options.timestamp
    ? moment().format(finalFormat)
    : moment.utc(options.timestamp).local().format(finalFormat);
}

//convert to UTC - used to store timestamps in db
export function db_timestamp(
  timestamp?: string | number | Date,
  isISO = false
): string {
  const momentObj = timestamp ? moment(timestamp) : moment();
  return isISO
    ? momentObj.toISOString()
    : momentObj.utc().format("YYYY-MM-DD HH:mm:ss");
}

interface TimestampValidation {
  value: string | number | Date;
}

export function validateTimestamp(timestamp: TimestampValidation): boolean {
  return timestamp.value
    ? parseInt(moment(timestamp.value).format("YYYY"), 10) > 1900
    : true;
}
